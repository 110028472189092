import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

const Benefits = () => {

  return (
    <div className="benefits page__body pt-5">
      <div className="container">
        <div className="benefits__wrapper">
          <div className="section mb-5">
            <h1 className="section__title mt-0 w-100">BENEFICIOS</h1>
            <p className="section__description">Olimpo te ofrece un sistema de comisiones atractivo, empezarás a ganar dinero y muchos beneficios por cada cliente activo que remitas y juegue con los productos de apuestas deportivas, casino en vivo y casino virtual.</p>
            {/* <ul className="list px-3">
              <li className="list__item"><img src={Ellipse} alt="" /> Beneficios de Apuestas Deportivas</li>
              <li className="list__item"><img src={Ellipse} alt="" /> Beneficios de Poker</li>
              <li className="list__item"><img src={Ellipse} alt="" /> Beneficios de Casino y Juegos</li>
            </ul> */}
          </div>
        </div>
        <div className="benefits__wrapper pb-5">
          <div className="section px-3 py-3">
            <h3 className="section__subTitle mt-0">Estructura de los beneficios</h3>
            <table className="table" border="0" >
              <tbody className="table__body">
                <tr>
                  <td>$ 0 - $ 10,000</td>
                  <td>25% Utilidad Neta de los Productos</td>
                </tr>
                <tr>
                  <td>$ 10,001 - $ 30,000</td>
                  <td>30% Utilidad Neta de los Productos</td>
                </tr>
                <tr>
                  <td>$ 30,001 - sin límite</td>
                  <td>35% Utilidad Neta de los Productos</td>
                </tr>
              </tbody>
            </table>
          </div>
            
          {/* <div className="section px-3 py-3 mb-5 mt-5">
            <h3 className="section__subTitle mt-0">NUEVO! Incentivo - Estructura</h3>
            <table className="table" border="0" >
              <tbody className="table__body">
                <tr>
                  <td>6 - 50 nuevos clientes con deposito</td>
                  <td>5% Beneficio Extra</td>
                </tr>
                <tr>
                  <td>&gt;50 nuevos clientes con deposito</td>
                  <td>10% Beneficio Extra</td>
                </tr>
              </tbody>
            </table>
          </div> */}
        </div>
      </div>
      <div className="tyc-in-benefices">
          <a className="" href="https://www.afiliaolimpo.bet/TC_Afiliados_Olimpo.pdf" target="_blank" >*Ver Términos y Condiciones</a>
      </div>
    </div >
  )
}


export default Benefits;