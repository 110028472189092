import React from 'react';
import { NavLink } from "react-router-dom";


const Menu = ({handleToogleMenu}) => {

  return (
    <nav className="navbar-main">
      <ul className="navbar" onClick={() => {handleToogleMenu()}}>
        <li className="navbar__item">
        <a className="navbar__button"  href="https://affiliates.afiliaolimpo.bet/affiliates"  target="_blank">LOGIN</a>
        </li >
        <li className="navbar__item">
          <NavLink className="navbar__link" activeClassName="link-active" to="/" exact={true}>INICIO</NavLink>
        </li>
        <li className="navbar__item">
          <NavLink className="navbar__link" activeClassName="link-active" to="/recompensas">BENEFICIOS</NavLink>
        </li>
        <li className="navbar__item">
          <NavLink className="navbar__link" activeClassName="link-active" to="/como-funciona">CÓMO FUNCIONA</NavLink>
        </li>
        {/* <li className="navbar__item">
          <NavLink className="navbar__link" activeClassName="link-active" to="/contact">CONTACTO</NavLink>
        </li> */}
        <li className="navbar__item">
          <NavLink className="navbar__link" activeClassName="link-active" to="/preguntas-frecuentes">PREGUNTAS FRECUENTES</NavLink>
        </li>
        <li className="navbar__item">
          <NavLink className="navbar__link" activeClassName="link-active" to="/productos">PRODUCTOS</NavLink>
        </li>
        <li className="navbar__item">
          <NavLink className="navbar__link" activeClassName="link-active" to="/registrate">REGÍSTRATE</NavLink>
        </li>
        <li className="navbar__item">
          <a className="navbar__button"  href="https://affiliates.afiliaolimpo.bet/affiliates"  target="_blank">LOGIN</a>
        </li>
      </ul>
    </nav>
  )
}

export default Menu;