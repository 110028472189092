import React, { Fragment } from "react";
import Productos from "../../assets/images/img_products.png";
import Ellipse from "../../assets/images/red_point.svg";

const CardBenefits = (props) => {
  const { description, title, listCaradSteps } = props;

  console.log("listCaradSteps",listCaradSteps);

  return (
    <div className="cardBenefits">
      <div className="cardBenefits__heading">
        <h2 className="cardBenefits__title">{title}</h2>
      </div>
      <div className="cardBenefits__body">
      
          <Fragment>
            {
              (description !== undefined ? 
                <p>{description}</p>:null)
            }
            {listCaradSteps !== undefined ? (
              <ul className="list">
                {listCaradSteps.map((list, index) => {
                  return (
                    <li className="list__item" key={index}>
                      <img src={Ellipse} alt="" />
                      {list}
                    </li>
                  );
                })}
              </ul>
            ) : null}
          </Fragment>
          {/* <Fragment>
            <img className="cardBenefits__body-img" src={Productos} alt="" />
          </Fragment> */}
      </div>
    </div>
  );
};

export default CardBenefits;
