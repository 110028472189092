import React, { Fragment, useState } from "react";
import Ellipse from "../../assets/images/red_point.svg";

const Products = () => {
  const productData = [
    {
      id: 0,
      question: "APUESTAS DEPORTIVAS",
      answer:
        "Contamos con tecnología y software de última generación, pertenecemos a una compañía con amplia experiencia en el mercado de apuestas. La más amplia oferta deportiva de torneos, ligas y campeonatos.",
      lista: [],
      status: true,
    },
    {
      id: 1,
      question: "CASINO",
      answer:
        "Contamos con la mejor oferta del mercado en Casino: tragamonedas, mesas virtuales y mesas en vivo con juegos de proveedores líderes en la industria. Asegurando la mejor experiencia de juego para nuestros usuarios.",
      lista: [],
      status: false,
    },
  ];

  const screenWidth = window.screen.width;
  const [viewPortWidth, setViewPortWidth] = useState(screenWidth);
  const [showAnswer, setShowAnswer] = useState(productData);

  window.addEventListener("resize", (e) => {
    if (e.target.visualViewport.width > 0) {
      setViewPortWidth(e.target.visualViewport.width);
    }
  });

  const changeAnswer = (id) => {
    const newProductData = showAnswer.map((item) => {
      if (id === item.id && !item.status) {
        return {
          ...item,
          status: true,
        };
      } else {
        return {
          ...item,
          status: false,
        };
      }
    });

    setShowAnswer(newProductData);
  };

  return (
    <Fragment>
      <div className="products page__body py-5">
        <div className="container">
          {
            viewPortWidth > 767 ? 
            (<h1 className="section__title mt-0 pr-5 w-49">PRODUCTOS</h1>) : null
          }
          <div className="products__wrapper-desktop">
            {productData.map((item) => {
              return (
                <div className="section" key={item.id}>
                  <h1 className="font-size-14 section__title mt-0 w-100 ">
                    {item.question}
                  </h1>
                  <p className="section__description ">{item.answer}</p>
                  <ul className="list px-3">
                    {item.lista.map((item) => {
                      return (
                        <li className="list__item d-flex">
                          <img className="mt-1 mx-4" src={Ellipse} alt="" />
                          <span>{item.text}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>

          <div className="products__wrapper-mobile">
            <h1 className="section__title mt-0 mb-1">PRODUCTOS</h1>
            <div
              className={
                "d-flex " + (viewPortWidth < 768 ? " acordeon-mobile " : "")
              }
            >
              <ul className="faq px-0 my-0">
                {showAnswer.map((item) => {
                  return (
                    <li className=" mobile__item pointer" key={item.id}>
                      <h3
                        className={
                          "mobile__item-question my-0 d-flex justify-content-between " +
                          (item.status ? " mobile__item-question-active " : " ")
                        }
                        onClick={(_) => changeAnswer(item.id)}
                      >
                        {item.question}
                        {viewPortWidth < 768 ? (
                          <span
                            className={
                              item.status
                                ? "icon-chevron-up"
                                : "icon-chevron-down"
                            }
                          ></span>
                        ) : null}
                      </h3>

                      <div className="mobile__item-answer ">
                        {item.status ? (
                          <div className="section">
                            <p className="section__description text-bold mt-0 pt-2">
                              {item.answer}
                            </p>
                            <ul className="list px-3">
                              {item.lista.map((item) => {
                                return (
                                  <li className="list__item d-flex">
                                    <img
                                      className="mt-1 mr-1"
                                      src={Ellipse}
                                      alt=""
                                    />
                                    <span>{item.text}</span>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        ) : null}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <br/>
          <br/>
          <p className="text-center mt-5 mb-0">
            Olimpo está comprometido a ofrecer las mejores promociones,
            productos y servicios.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default Products;
