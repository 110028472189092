import React from 'react';
import banner from "../../assets/images/banner-afiliados.png"


const MainBanner = () => {

  return (
    <div className="main-banner">
      <img className="main-banner__img" src={banner} alt="" />
    </div>
  )
}


export default MainBanner;