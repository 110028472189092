import React from "react";

const SignUp = () => {

  const iframe = () => {
    return {
      // __html: `<iframe width=”1000px” height=”1230px”  allowtransparency="true" src=”https://affiliates.afiliaolimpo.bet/signup.html?l=48?c=signup” style=”padding: 0px; background:none” scrolling="no"/></iframe>`
      __html: '<iframe class="signup-iframe" src="https://affiliates.afiliaolimpo.bet/signup.html?l=48?c=signup" allowtransparency="true" frameborder="0" width="100%" scrolling="no" style="background:none"></iframe>'
    }
  }

  return (
    <div className="sign-up py-5">
      <div className="container">
        <h1>REGÍSTRATE</h1>
        <div className="iframe-contents">
          <div dangerouslySetInnerHTML={ iframe() } />
          <div className="tyc_box">
          <h4>Terminos y Condiciones</h4>
          <p className="tyc_textarea">
        El sitio www.afiliaolimpo.bet y/o www.olimpo.bet es operado por NG Gaming N.V., en
        virtud de la sub-licencia otorgada por la empresa Antillephone N.V. (“CIL”) conforme a
        la Master Gaming License N°8048/JAZ, en cumplimiento de las Leyes de Curazao (en
        adelante, “Olimpo” o, indistintamente, la “Compañía”).
        <br/> <br/>
        Al completar el formulario de Registro de Afiliación del Programa de Afiliados de Olimpo
        (“Programa de Afiliados”) y dar click en el botón “Acepto” de dicho formulario, usted (en
        adelante, el “Afiliado”) acepta que ha leído, está de acuerdo y, por tanto, en caso sea
        aceptado por la Compañía, estará sujeto y se compromete en cumplir a cabalidad con
        los presentes términos y condiciones (los “Términos y Condiciones”).
        <br/> <br/>
        La Compañía se reserva el derecho de corregir, alterar, eliminar o añadir cualquier
        disposición de los Términos y Condiciones, en cualquier momento y a su única
        discreción, sin aviso previo al Afiliado. En tal sentido, los Términos y condiciones
        aplicables en cada momento serán los que en dicha oportunidad se encuentren
        publicados en www.afiliaolimpo.bet y/o www.olimpo.bet. En esa medida, el Afiliado se
        compromete a revisar constantemente los Términos y Condiciones a los que se
        encuentra sujeto.
        <br/> <br/>
        La continua (i) participación en el Programa de Afiliados, (ii) utilización de la página web
        de Afiliados y/o Herramientas de Marketing (definidas más adelante), o (iii) la aceptación
        de cualquier pago por parte del Afiliado confirma su irrevocable aceptación de los
        Términos y Condiciones.
        <br/> <br/>
        1. PROPÓSITO
        <br/> <br/>
        1.1. Olimpo ofrece a sus clientes los productos de apuestas más reconocidos del
        mercado como apuestas deportivas, poker y casino, desde la página
        web www.olimpo.bet
        <br/> <br/>
        1.2. El Afiliado mantiene u opera una o más páginas web o cuentas en redes sociales
        (indistintamente, los “Medios del Afiliado”) y se encuentra interesado en
        promover la página web de Olimpo entre su audiencia a cambio de una
        contraprestación.
        <br/> <br/>
        1.3. Estos Términos y Condiciones contienen los términos y condiciones aplicables
        a la promoción de la página web de Olimpo por parte del Afiliado, así como la
        estructura de pagos que podría recibir el Afiliado, dependiendo del tráfico y los
        nuevos usuarios que genere en www.olimpo.bet.
        <br/> <br/>
        2. ACEPTACIÓN DE UN AFILIADO
        <br/> <br/>
        2.1. La Compañía evaluará el formulario de registro del Afiliado disponible en esta
        web e informará al potencial Afiliado si su solicitud ha sido aceptada o no. La
        Compañía se reserva el derecho de rechazar la aplicación sin expresar razón
        alguna.
        <br/> <br/>
        3. CONDICIONES ESENCIALES
        <br/> <br/>
        3.1. El solicitante/Afiliado garantiza que:
        <br/> <br/>
        3.1.1. Cuenta con la edad legal conforme a su jurisdicción (i.e. 18 años y/o el mínimo
        legal equivalente en su país) para aceptar los Términos y Condiciones.
        <br/> <br/>
        3.1.2. Es competente y está debidamente autorizado para entrar en un acuerdo
        vinculante con Olimpo.<br/> <br/>
        
        3.1.3. Es el propietario de todos los derechos, licencias y permisos para comercializar,
        promover y publicitar Olimpo acorde con el propósito de estos Términos y
        Condiciones.
        <br/> <br/>
        3.1.4. Cumplirá con todas las normas, leyes y reglamentaciones aplicables con
        respecto a la promoción de Olimpo.
        <br/> <br/>
        3.1.5. Entiende y acepta completamente los Términos y Condiciones.
        <br/> <br/>
        4. RESPONSABILIDADES Y OBLIGACIONES DE LA COMPAÑÍA
        <br/> <br/>
        4.1. La Compañía asignará al Afiliado un enlace para poder direccionar usuarios de
        los Medios del Afiliado a la página web de Olimpo (el “Link”). El Link permitirá a
        la Compañía identificar a los nuevos usuarios provenientes de los Medios del
        Afiliado.
        <br/> <br/>
        4.2. La Compañía proveerá al Afiliado de información y marketing necesario para la
        buena implementación del Link en los Medios del Afiliado.
        <br/> <br/>
        4.3. La Compañía será responsable y se encargará de todos los servicios prestados
        a los usuarios. Olimpo llevará a cabo el control sobre los nuevos usuarios y los
        pagos al Afiliado, además de controlar cualquier fraude o abuso.
        <br/> <br/>
        4.4. La Compañía hará seguimiento de las ganancias generadas por los nuevos
        usuarios que ingresaron a través del Link y proveerá las estadísticas al Afiliado.
        Para dicho fin, la Compañía asignará un único código de seguimiento a todos
        los usuarios referidos por cada Afiliado.
        <br/> <br/>
        4.5. La Compañía pagará al afiliado la cantidad dependiendo de los ingresos
        generados por los nuevos usuarios, conforme se detalla en la sección 6 de estos
        Términos y Condiciones.
        <br/> <br/>
        4.6. La Compañía rechazará a cualquier usuario o cerrará cualquier cuenta de un
        jugador si es necesario para cumplir con las políticas y/o proteger los intereses
        de la Compañía.
        <br/> <br/>
        4.5. La Compañía cerrará la cuenta de Afiliado si se detecta un intento de fraude por
        parte del Afiliado o si incumple alguno de los puntos de los presentes Términos
        y Condiciones.
        <br/> <br/>
        5. RESPONSABILIDADES Y OBLIGACIONES DEL AFILIADO
        <br/> <br/>
        5.1. Por la presente, el Afiliado asume el compromiso de promover con exclusividad
        a Olimpo. En tal sentido, no podrá contratar o prestar servicios de ningún tipo a
        terceros que ofrezcan servicios similares a los de Olimpo. Además, garantiza y
        asume:
        <br/> <br/>
        5.1.1. Que empleará los mejores esfuerzos para promocionar, promover y publicitar
        Olimpo de manera eficiente y activa tanto como le sea posible con el fin de 
        maximizar el beneficio de ambas partes y que lo hará dentro de las pautas de la
        Compañía que le serán enviadas periódicamente y las podrá consultar en la web.
        <br/> <br/>
        5.1.2. Que promocionará y referirá usuarios potenciales a Olimpo a su expensa y
        riesgo. El afiliado será el único responsable de la distribución, contenido y
        maneras respecto de sus actividades de marketing. Todas las actividades de
        promoción del Afiliado deben ser profesionales y legítimas bajo la ley aplicable,
        y las negociaciones realizadas conforme a estos Términos y Condiciones.
        <br/> <br/>
        5.1.3. Que utilizará el Link, de otro modo no se puede garantizar en absoluto que el
        registro y el pago de la contraprestación quede bien registrado en el sistema de
        Olimpo. Asimismo, no cambiará o modificará de ninguna manera ningún material
        de marketing sin autorización escrita previa de la Compañía.
        <br/> <br/>
        5.1.4. Que se hará responsable del desarrollo, operativa y mantenimiento de los
        Medios del Afiliado, así como del material que aparece en ellos.
        <br/> <br/>
        5.2. Asimismo, el Afiliado garantiza:
        <br/> <br/>
        5.2.1. Que no llevará a cabo ningún acto difamatorio, discriminatorio, obsceno, ilegal
        ni tampoco que expondrá materiales inconvenientes o que contengan sexo
        explícito, pornográfico, obsceno o violento.
        <br/> <br/>
        5.2.2. Que no buscará captar activamente a personas que estén por debajo de la edad
        legal para apostar.
        <br/> <br/>
        5.2.3. Que no captará activamente en jurisdicciones donde el juego online y su
        promoción son ilegales.
        <br/> <br/>
        5.2.4. Que no generará tráfico a Olimpo mediante actividades fraudulentas, incluyendo,
        pero no limitadas a:
        <br/> <br/>
        i. enviar spam.
        <br/> <br/>
        ii. incluir meta tags incorrectos.
        <br/> <br/>
        iii. registrarse como jugador o ingresar directa o indirectamente a
        cualquier cuenta de cualquier jugador mediante su Link de
        seguimiento para su beneficio personal o de amigos, empleados u
        otros beneficiarios, o empleando cualquier otra manera de
        incrementar “artificialmente” las ganancias u otros tipos de fraude a la
        Compañía.
        <br/> <br/>
        La violación de estas reglas se considerará fraude.
        <br/> <br/>
        5.2.5. Que no expresará o presentará en los Medios del Afiliado de ninguna manera
        que genere riesgo de inducir a la confusión a los usuarios de que los Medios del
        Afiliado (o parte de ellos), tienen su origen o son parte de la Compañía de
        manera oficial.
        <br/> <br/>
        5.2.6. El Afiliado únicamente podrá usar el material de marketing que le sea enviado
        por la Compañía y/o esté a su disposición en www.afiliaolimpo.bet. No podrá
        utilizar otros elementos creados por la Compañía sin su autorización previa y por
        escrito.
        <br/> <br/>
        5.2.7. El Afiliado proporcionará su información completa y correcta a Olimpo. La
        información debe incluir, pero no se limita a, la identidad del Afiliado, información
        de contacto actualizada, información sobre los Medios del Afiliado, instrucciones
        para los pagos, dirección, la naturaleza de las actividades de marketing que
        realiza o realizará, y cualquier otra información que Olimpo solicite.
        <br/> <br/>
        5.2.8. Los Afiliados no podrán ofrecer ningún tipo de reembolso, cash back o
        promociones similares.
        <br/> <br/>
        5.2.9. El Afiliado acepta compensar a la Compañía por cualquier pérdida o gasto
        debido o en relación al incumplimiento de estos Términos y Condiciones por
        parte del Afiliado.
        <br/> <br/>
        5.2.10. El Afiliado debe promocionar de manera obligatoria e indeclinable las "campañas
        oficiales" de Olimpo.
        <br/> <br/>
        5.2.11. Olimpo compartirá con el Afiliado las piezas gráficas / textos sugeridos / URL /
        otros, de las campañas oficiales que realice, así como la duración de las mismas.
        <br/> <br/>
        5.2.12. El Afiliado deberá realizar al menos dos (2) publicaciones semanales en los
        Medios del Afiliado, en horas de mayor interacción, durante el periodo de
        duración de las campañas oficiales que Olimpo realice.
        <br/> <br/>
        5.2.13. Durante las campañas oficiales, el Afiliado deberá enviar un correo a
        afiliados@betsoffice.com con la programación de las publicaciones de la
        semana, indicando el detalle del día y la hora de cada publicación. Si el correo
        no es recibido en los primeros tres (3) días de la semana en curso, no será
        tomado en consideración para la evaluación de rendimiento.
        <br/> <br/>
        5.2.14. El Afiliado deberá realizar las siguientes acciones en los Medios del Afiliado
        registrados en el proceso de afiliación:
        <br/> <br/>
        a. "Fijar en la parte superior" la gráfica o promoción enviada por Olimpo.
        <br/> <br/>
        b. Tener la "Portada" de la promoción diseñada por Olimpo y personalizada
        para el Medio del Afiliado.
        <br/> <br/>
        c. Olimpo debe tener la exclusividad en todas las menciones que se hagan
        con referencia a las apuestas y jugadas.
        <br/> <br/>
        5.2.15. Si el Medio del Afiliado incluye la palabra Olimpo en el nombre, sin causar
        confusión en los usuarios sobre la autoría de la misma, no se puede hacer
        mención o publicidad a otra casa de apuestas que no sea Olimpo. Este canal
        deberá ser único y exclusivo para contenido referente a la Compañía.
        <br/> <br/>
        5.2.16. Si el canal de comunicación del Afiliado es un blog, deberá realizar las siguientes
        acciones:
        <br/> <br/>
        a. Colocar de manera visible un banner de la "campaña oficial".
        <br/> <br/>
        b. Publicar una entrada semanal en donde se incluya información de la
        "campaña oficial".
        <br/> <br/>
        c. Cuidar el nombre e imagen de Olimpo frente a la competencia.
        <br/> <br/>
        5.2.17. El no cumplimiento de la promoción de la "campaña oficial" tendrá como
        penalidad el pago solo del 5% del mes de curso (en vez del 25% de afiliación
        estándar o la escala aplicable que se encuentre vigente en la página web
        www.afiliaolimpo.bet).
        <br/> <br/>
        5.2.18. El no cumplimiento de dos (2) "campañas oficiales" es motivo de finalización de
        este acuerdo, por lo que la cuenta del Afiliado estará sujeta a evaluación por
        parte de la Compañía.
        <br/> <br/>
        5.2.19. El Afiliado no podrá usar los canales de comunicación de Olimpo para beneficio
        propio y queda expresamente prohibido fomentar o publicitar en dichos canales
        (sea Facebook, página web, blogs, etc.) códigos de bono o publicidad para el
        beneficio del Afiliado y/o sus subafiliados.
        <br/> <br/>
        6. PAGOS
        <br/> <br/>
        6.1. La Compañía acuerda pagar al Afiliado de acuerdo a los ingresos netos
        generados únicamente por los usuarios referidos que hayan realizado una
        primera recarga de USD.10 (diez y 00/100 Dólares de los Estados Unidos de
        América)
        <br/> <br/>
        6.2. La Compañía acuerda pagar al Afiliado por la promoción del portal de la
        Compañía de acuerdo a los ingresos netos generados por parte de los nuevos
        usuarios referidos (que cumplan con la condición mencionada en el inciso 6.1)
        desde los Medios del Afiliado a través del Link.
        <br/> <br/>
        La Utilidad Neta de los productos se calcularán con la siguiente fórmula:
        <br/> <br/>
        i. En relación con los ingresos netos sobre apuestas deportivas, deberán ser
        calculados como el dinero apostado (sumas del dinero real apostado por los
        nuevos usuarios) menos sus ganancias, menos anulaciones y devoluciones,
        cargos de fraude y "chargebacks", bonos, freebets y pagos promocionales
        realizados desde Olimpo a los nuevos usuarios, y los pagos a los
        proveedores de cuotas deportivas. Adicionalmente se restarán otros cargos
        administrativos.
        <br/> <br/>
        ii. En relación con los ingresos netos sobre el casino, se calculan a partir de las
        ganancias netas generadas por los jugadores de casino recibidas por
        Olimpo, restando las ganancias, los cargos de fraude y “chargebacks”, bonos
        y pagos promocionales realizados desde Olimpo a los nuevos usuarios, y los
        pagos a los proveedores del software. Adicionalmente se restarán otros
        cargos administrativos.
        <br/> <br/>
        6.3. El pago por la promoción se calcula a los quince (15) días de cada mes vencido
        y los pagos se transferirán entre el día 15 y 30 de cada mes calendario siguiente
        al vencido, siempre que la cantidad a pagar supere los USD 50.00 (cincuenta y
        00/100 Dólares de los Estados Unidos de América) (el “Límite mínimo”) o su
        equivalente en moneda local. Si el balance no supera este Límite Mínimo, se
        acumulará en el siguiente mes y así sucesivamente hasta que supere dicho
        Límite Mínimo.
        <br/> <br/>
        6.4. Los pagos se harán mediante el método establecido por la Compañía, tomando
        la información proporcionada por el Afiliado durante el proceso de afiliación. Si
        se produce algún error en el pago, la Compañía se reserva el derecho de corregir 
        el cálculo en cualquier momento y realizar el pago de la deuda o reclamar el
        monto extra hecho al Afiliado.
        <br/> <br/>
        6.5. La aceptación del pago por parte del Afiliado se considerará como el cierre
        correcto del balance y la deuda para el periodo correspondiente.
        <br/> <br/>
        6.6. Si un afiliado no está de acuerdo con el balance tal y como se indica en el
        reporte, deberá enviar un e-mail a la Compañía a facturación@betsoffice.com en
        el plazo de treinta (30) días calendario indicando las razones de su desacuerdo.
        No enviar este e-mail dentro del tiempo límite se considerará como la irrevocable
        aceptación del balance en el periodo indicado.
        <br/> <br/>
        6.7. La Compañía puede retrasar el pago de cualquier balance a un Afiliado durante
        ciento ochenta (180) días, mientras investiga y verifica cualquier transacción
        relevante cumpliendo con las disposiciones de estos Términos y Condiciones.
        <br/> <br/>
        6.8. No se llevará a cabo ningún pago cuando el tráfico generado sea ilegal o
        contravenga cualquier disposición de estos Términos y Condiciones.
        <br/> <br/>
        6.9. El Afiliado acuerda devolver el pago recibido basado en transacciones falsas o
        fraudulentas, además de todos los costes por causas legales o acciones que
        puedan derivar contra el Afiliado con todo el peso de la ley.
        <br/> <br/>
        6.10. Todos los Afiliados deben referir un mínimo de cinco (5) nuevos usuarios cada
        mes, de no cumplir con este requisito no se llevará a cabo ningún pago y la
        Compañía se reserva el derecho de cerrar su cuenta de afiliación. El Afiliado
        tendrá derecho a solicitar una nueva cuenta en una etapa posterior.
        <br/> <br/>
        6.11. El Afiliado debe especificar el método de pago en el Formulario de Registro. La
        Compañía no se hará responsable de ningún error en los datos de registro o en
        las instrucciones para los pagos que el Afiliado facilite. La Compañía intentará
        en la medida de lo posible acomodarse a las preferencias de pago que el usuario
        priorice, aun así, puede cambiar el método de pago.
        <br/> <br/>
        6.12. La Compañía no asume ningún tipo de responsabilidad con relación a los
        impuestos, multas, e intereses que pudieran vincularse con los impuestos
        aplicables al pago recibido por el Afiliado. Es responsabilidad del Afiliado la
        liquidación y el pago de sus impuestos.
        <br/> <br/>
        6.13. La comisión bancaria aplicable al método de pago elegido por el Afiliado, son
        total y completamente responsabilidad de Afiliado. La Compañía no se hace
        responsable por los costos que le puedan generar al Afiliado las distintas
        comisiones bancarias.
        <br/> <br/>
        6.14. Para recibir el pago, el Afiliado deberá emitir los comprobantes de pago u otros
        documentos oficiales según el país de residencia del Afiliado (solicitar los datos
        de la compañía a afiliados@betsoffice.com). Estos documentos deberán ser
        enviados en forma digital al siguiente correo: facturación@betsoffice.com
        <br/> <br/>
        7. FINALIZACIÓN
        <br/> <br/>
        7.1. Estos Términos y Condiciones pueden ser finalizados por cualquiera de las
        partes mediante una notificación por escrito a la otra parte. Dicha notificación por
        escrito debe ser enviada por e-mail.
        <br/> <br/>
        7.2. Por la presente, las Partes Contratantes acuerdan que a la finalización del
        Acuerdo:
        <br/> <br/>
        7.2.1. El Afiliado eliminará cualquier referencia de Olimpo de los Medios del Afiliado y
        de las comunicaciones, independientemente de si las comunicaciones son
        publicitarias o no.
        <br/> <br/>
        7.2.2. Todos los derechos y licencias garantizadas al Afiliado bajo estos Términos y
        Condiciones finalizarán inmediatamente y el Afiliado cesará en el uso de
        cualquier marca, servicio, logo u otros diseños basados en Olimpo.
        <br/> <br/>
        7.2.3. El Afiliado solo tendrá derecho al pago hasta la fecha efectiva de finalización; sin
        embargo, La Compañía puede retener el pago final de un Afiliado durante un
        periodo de tiempo razonable para asegurar el pago de la cantidad correcta. El
        Afiliado no podrá recibir pagos por la promoción del portal después de esa fecha.
        <br/> <br/>
        7.2.4. En caso de incumplimiento de los Términos y Condiciones por parte del Afiliado,
        la Compañía tendrá el derecho de retener los pagos del Afiliado como garantía
        para cualquier reclamo que pueda surgir de dicho incumplimiento.
        <br/> <br/>
        7.2.5. El Afiliado deberá devolver a la Compañía toda la información confidencial de
        cualquier tipo (y todas las copias y derivados de dichas copias) que estén en
        posesión, custodia y control del Afiliado.
        <br/> <br/>
        7.2.6. El Afiliado liberará a la Compañía de todas sus obligaciones y responsabilidades
        que se produzcan después de la fecha de finalización, a excepción de las que
        hagan referencia a aquellas obligaciones diseñadas para la misma finalización.
        La Finalización no libera al Afiliado de ninguna responsabilidad que se produzca
        a raíz del incumplimiento de los Términos y Condiciones producido previamente
        a la finalización.
        <br/> <br/>
        8. GARANTÍAS
        <br/> <br/>
        8.1. El Afiliado conoce expresamente y está de acuerdo con que asume el riesgo del
        uso de internet y que este “Programa de Afiliados” es proporcionado “tal cual” y
        “como disponible” sin ninguna garantía ni condición, explícita o implícitamente.
        No hay garantía que la Compañía dará posibilidad de acceso a su página web
        en ningún momento en particular o en un sitio en particular.
        <br/> <br/>
        8.2. La Compañía no se hará responsable de ningún error, omisión, pérdida o daño
        del Afiliado causada por un fallo, retraso o interrupción de toda o parte de la
        página de Olimpo o del Programa de Afiliados. Olimpo no garantiza que el
        sistema, red, software o hardware (o de las terceras partes proveedoras a
        Olimpo) están libres de errores o sin interrupciones.
        <br/> <br/>
        9. INDEMNIZACIÓN
        <br/> <br/>
        9.1. El Afiliado acuerda defender, indemnizar y apoyar a la Compañía y sus afiliados,
        sucesores, delegados, empleados, agentes, directores, socios y abogados,
        libremente y sin causar daños, de y contra todas y cada una de las
        responsabilidades, incluidas comisiones de abogados y expertos, relativos o
        incluyendo:
        <br/> <br/>
        i. Cualquier incumplimiento de una protesta, garantía o acuerdo bajo estos
        Términos y Condiciones.
        <br/> <br/>
        ii. Uso o mal uso de cualquier material de marketing.
        <br/> <br/>
        iii. Todas las conductas o actividades sucedidas bajo el código único y
        contraseña del Afiliado.
        <br/> <br/>
        iv. Cualquier difamación, calumnia o material ilegal incluida en los Medios del
        del Afiliado o en los datos y/o información del Afiliado.
        <br/> <br/>
        v. Cualquier reclamación o desacuerdo de los Medios del Afiliado o de sus
        datos e información que infrinja las patentes, copyright, marca u otro tipo
        de propiedades intelectuales de una tercera parte o viole los derechos de
        privacidad o publicación de una tercera parte.
        <br/> <br/>
        vi. El acceso por parte de terceros al uso del código único del Afiliado o de los
        datos e información del Afiliado.
        <br/> <br/>
        vii. Cualquier reclamación relativa al uso del código único del Afiliado.
        <br/> <br/>
        viii. Cualquier violación de estos Términos y Condiciones.
        <br/> <br/>
        9.2. La Compañía se reserva el derecho de participar, bajo su cuenta y riesgo, en
        defensa de cualquier caso que considere conveniente.
        <br/> <br/>
        10. ASPECTOS LEGALES
        <br/> <br/>
        10.1. Estos Términos y Condiciones están sometido a las leyes de Curacao y cualquier
        acción relativa a ellos debe ser llevada antes los jueces y cortes de Curacao. El
        Afiliado renuncia al fuero de su jurisdicción y consiente irrevocablemente esa
        jurisdicción para cualquier controversia derivada de estos términos y
        condiciones.
        <br/> <br/>
        10.2. El Afiliado autoriza y otorga a NG Gaming N.V. por tiempo indefinido, su
        consentimiento libre, previo, expreso, inequívoco e informado para que (por sí
        mismo o a través de terceros) recopile, registre, organice, almacene, conserve,
        elabore, modifique, bloquee, suprima, extraiga, consulte, utilice, transfiera,
        exporte, importe o procese (trate) sus datos personales, conforme a la
        Ordenanza Nacional sobre la Protección de Datos Personales de Curazao
        (Landsverordening bescherming persoonsgegevens) del 04 de septiembre de
        2010, vigente desde el 01 de Octubre de 2013 o cualquier otra ley de Curazao
        que la sustituya o complemente.
        <br/> <br/>
        Asimismo, mediante su aceptación, El Afiliado autoriza a NG NG Gaming N.V. a
        elaborar un Banco de Datos con la información proporcionada y a tratar sus
        datos personales proporcionados mediante el presente registro para enviarle
        información sobre promociones, descuentos, invitaciones a eventos, realizar
        encuestas, así como para transferir sus datos personales y compartirlos con
        terceros exclusivamente para fines de publicidad y prospección comercial,
        gestión de lista de clientes, gestión económica y contable de clientes, fines
        estadísticos y cualquier otro que consideremos relevante con el propósito de dar
        un servicio de atención al cliente de calidad y eficiente y un mejor funcionamiento
        del sistema de afiliados.
        <br/> <br/>
        Finalmente, mediante su aceptación, el Afiliado autoriza a NG Gaming N.V. a
        transferir estos datos al servidor ubicado en Reino Unido, de propiedad de Arnold
        Media Limited, para su almacenamiento. Cualquier solicitud sobre sus datos
        personales puede ser dirigida al siguiente correo electrónico:
        afiliados@betsoffice.com, o por escrito a NG Gaming N.V. a la siguiente
        dirección física: Heelsumstraat 51, E-Commerce Park, Vredenberg,
        Willemstead, Curacao
        <br/> <br/>
        11. CESIÓN A TERCEROS
        <br/> <br/>
        11.1. El Afiliado no puede ceder ninguno de sus derechos ni obligaciones conforme a
        estos Términos y Condiciones a terceros sin consentimiento previo y escrito de
        la Compañía.
        <br/> <br/>
        12. RENUNCIA
        <br/> <br/>
        12.1. El fallo por parte de la Compañía en hacer cumplir al Afiliado los términos
        especificados en estos Términos y Condiciones no constituirán una renuncia del
        derecho de la Compañía a hacer cumplir dichos términos en cualquier momento.
        <br/> <br/>
        13. FUERZA MAYOR
        <br/> <br/>
        13.1. Ninguna de las partes puede responsabilizar a la otra por ningún retraso o fallo
        al desarrollar las obligaciones definidas en estos Términos y Condiciones si dicho
        retraso o fallo se debe a una causa fuera de control razonable y no es una falta
        de dicha parte, incluyendo, pero no limitando a disputas laborales, huelga, actos
        de Dios, actos de terrorismo, inundaciones, rayos, fallo del sistema de
        comunicaciones, terremotos u otras causas. Si se produce un caso de fuerza
        mayor, la parte no implicada esta excusada de cumplir la función que el caso de
        fuerza mayor le impide desarrollar.
        <br/> <br/>
        14. RELACIÓN ENTRE LAS PARTES
        <br/> <br/>
        14.1. Nada que contenga estos Términos y Condiciones, ni acción tomada por alguna
        parte, deberá ser considerada como vinculante entre las partes o a constituir un
        trabajo (o cualquier tipo de empleo, agente o represéntate de las partes),
        representación legal de otra de las partes implicadas, ni para crear cualquier
        colaboración, empresa común, asociación, vinculo o sindicato entre las partes,
        ni para conferir/conceder en cualquiera de las partes el derecho o autoridad
        explícita o implícita para introducirse en ningún acuerdo o responsabilidad de
        parte de (tampoco imponer ninguna obligación sobre) la otra parte.
        <br/> <br/>
        15. SEPARACIÓN
        <br/> <br/>
        15.1. Siempre que sea posible, cada disposición de estos Términos y Condiciones
        deberá ser interpretada de tal manera que esté cumpliendo con lo establecido
        en las leyes aplicables, pero si algún apartado de estos Términos y Condiciones
        fuera susceptible de ser invalido o ilegal en cualquier aspecto, dicho apartado
        pasaría a ser invalidado solo en la parte que es susceptible de tal caso, sin
        invalidar en ningún momento el resto de los Términos y Condiciones.
        <br/> <br/>
        16. CONFIDENCIALIDAD
        <br/> <br/>
        16.1. Toda la información, incluyendo, pero no limitada del negocio de la Compañía y
        las finanzas, listas de usuarios y consumidores, así como la información de
        precios y ventas debe ser tratada con estricta confidencialidad. Cualquier
        información de este tipo que pudiera acceder el Afiliado no podrá ser usada por
        él, para usos comerciales propios u otros propósitos, ni directa ni indirectamente.
        Esta disposición lo obliga inclusive luego de finalizado el vínculo del Afiliado con
        la Compañía por cualquier causa.
        <br/> <br/>
        Por favor, imprima este documento y guarde una copia en sus archivos.
        </p>
          </div>
            <a className="register-terms" href="https://www.afiliaolimpo.bet/TC_Afiliados_Olimpo.pdf" target="_blank" >Términos y Condiciones</a>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
