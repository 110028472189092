import { Fragment } from 'react';
import CardBenefits from '../../components/cardBenefits/cardBenefits';
import CardSteps from '../../components/cardSteps/cardSteps';


const Home = () => {

  let listCaradSteps= [
    "Contamos con excelente tecnología para poder brindarles a nuestros clientes la mejor oferta y experiencia de juego.",
    "Te ofrecemos comisiones atractivas, gana hasta el 35% de revenue share.",
    "Pagos rápidos a nuestros clientes y red de afiliados.",
    "Inversión agresiva en publicidad y difusión de la marca.",
    "Herramientas de marketing personalizadas.",
    "Amplio horario de atención de soporte a nuestros afiliados."
  ];

  return (
    <Fragment>
      <div className="card-steps-content py-5">
        <div className="container">
          <CardSteps
            number={1}
            title={'REGÍSTRATE'}
            description={'Regístrate y completa el formulario. ¡Es rápido y sencillo!'}
            register={false}
          />
          <CardSteps
            number={2}
            title={'PROMOCIONA'}
            description={'Ponemos a tu disposición herramientas de marketing personalizadas para promocionar Olimpo '}
            register={false}
          />
          <CardSteps
            number={3}
            title={'EMPIEZA A GANAR'}
            description={'¡Conviértete en nuestro afiliado y por cada usuario que remitas a Olimpo empezarás a ganar!'}
            register={false}
          />
        </div>
      </div>

      <div className="page mt-5">
        <div className="page__body container">
          <div className="card-benefit-content">
            <CardBenefits title={"¿QUÉ ES AFILIA OLIMPO?"} description={"Afilia Olimpo es un programa de afiliados que busca tener socios estratégicos a través de sus productos de apuestas más reconocidos en el mercado como apuestas deportivas y Casino"} texto={true} />
            <CardBenefits title={"¿POR QUÉ CONVERTIRSE EN UN AFILIADO DE OLIMPO?"} listCaradSteps={listCaradSteps} texto={false} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Home;