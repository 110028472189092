import React, { Fragment } from "react";
import Logotipo from "../../assets/images/logotipo.png";

const HowWork = () => {
  return (
    <Fragment>
      <div className="benefits page__body pt-5">
        <div className="container d-flex direction-column">
          <h1 className="section__title mt-0">CÓMO FUNCIONA</h1>
          <div className="section  mb-5 mt-0">
            <p className="section__description">
              Si tienes páginas web o redes sociales con alcance e influencia
              para atraer nuevos jugadores a{" "}
              <a href="http://olimpo.bet" target="_blank">
                www.olimpo.bet{" "}
              </a>
              puedes ganar dinero fácilmente formando parte de nuestro programa
              Afilia Olimpo. Para comenzar a ser afiliado de Olimpo sólo tienes
              que registrarte completando nuestro formulario en la sección
              Regístrate o escribirnos a{" "}
              <a href="#">contacto@afiliaolimpo.bet </a>
            </p>
            <p className="section__description">
              Recibirás un correo con la aprobación de tu registro y la
              confirmación de la activación de tu cuenta de afiliado Olimpo,
              donde tendrás acceso a todas herramientas de marketing e
              información completa de las promociones vigentes. Se generará un
              link o código promocional único para cada afiliado para poder
              medir cuántos nuevos usuarios nos has remitido desde tu web o
              redes sociales. Los jugadores que se registren en nuestra web{" "}
              <a href="http://olimpo.bet" target="_blank">
                www.olimpo.bet{" "}
              </a>{" "}
              utilizando tu código promocional o sean remitidos mediante tu link
              se convertirán en tus usuarios. Podrás ver el estado actualizado
              de las ganancias que tus usuarios generen diariamente en tu cuenta
              de afiliado y la comisiones que recibirás. ¡Cuántos más usuarios
              remitas a Olimpo, más dinero ganarás!
            </p>
            <p className="section__description">
              Encuentra más información sobre la escala de comisiones de Olimpo
              en la sección de Beneficios.
            </p>
            <div className="my-5 pt-3">
              <img className="d-block m-auto" src={Logotipo} alt="" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default HowWork;
