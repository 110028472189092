import React, { Fragment, useState } from "react";

const Faq = () => {
  const faqData = [
    {
      id: 0,
      question: "¿Qué es un programa de afiliados?",
      answer:
        "Es un acuerdo mediante el cual una empresa o una persona natural que tiene tráfico o alcance envía a través de tecnologías de seguimiento (links, códigos promocionales) nuevos usuarios a nuestra web",
      answer2: "que realicen acciones como registros y depósitos.",
      status: true,
      link: "www.olimpo.bet",
    },
    {
      id: 1,
      question: "¿Cómo gano dinero con Olimpo?",
      answer:
        "Obtendrás una comisión sobre las ganancias netas de Olimpo generadas por todos los jugadores que remitas a la web. Mientras más jugadores traigas a nuestra web, mayores serán tus ganancias ya que contamos con un plan de comisiones escalonado.",
      answer2: null,
      status: false,
      link: null,
    },
    {
      id: 2,
      question: "¿Cómo puedo convertirme en afiliado de Olimpo?",
      answer:
        "Es muy fácil, ingresa a la sección de registro y completa todos tus datos. Nosotros verificaremos la información recibida y te confirmaremos por mail el registro como afiliado de ",
      answer2: null,
      status: false,
      link: "www.olimpo.bet",
    },
    {
      id: 3,
      question: "¿Cómo recibo mis ganancias como afiliado de Olimpo?",
      answer:
        "Nuestro programa de afiliados cuenta con los métodos de pago más usados en el mercado y también con transferencias bancarias. En el momento que te registres deberás ingresar la información que sea más adecuada para ti y nosotros realizaremos los pagos según los periodos especificados en nuestros términos y condiciones.",
      answer2: null,
      status: false,
      link: null,
    },
    {
      id: 4,
      question:
        "¿Cómo sé si mi sitio web o red social está calificado para ser afiliado de Olimpo?",
      answer:
        "Luego de haberte registrado, nosotros verificaremos tu sitio web o red social para confirmar que cumpla con los estándares determinados en el programa Afilia Olimpo. Recuerda que es posible que se te pidan algunos datos de tu web o red social para verificar la calidad de la misma. Afilia Olimpo se reserva el derecho de aprobar los nuevos registros de afiliados.",
      answer2: null,
      status: false,
      link: null,
    },
    {
      id: 5,
      question:
        "¿Debo realizar algún pago para convertirme en afiliado de Afilia Olimpo?",
      answer:
        "No, el registro a nuestro programa de afiliados es totalmente gratuito.",
      answer2: null,
      status: false,
      link: null,
    },
    {
      id: 6,
      question:
        "¿Puedo pertenecer a otro programa de afiliados estando en Afilia Olimpo?",
      answer:
        "Sí, puedes trabajar con nosotros y con otros programas de afiliados.",
      answer2: null,
      status: false,
      link: null,
    },
    {
      id: 7,
      question:
        "¿Con qué herramientas de promociones cuento para promocionar Afilia Olimpo?",
      answer:
        "Contamos con las mejores promociones de bienvenida para nuestros clientes. También podrás encontrar dentro de nuestra plataforma todas la nuevas promociones vigentes y novedades de nuestros productos que podrás promocionar con las herramientas de marketing que te brindaremos.",
      answer2: null,
      status: false,
      link: null,
    },
    {
      id: 8,
      question: "¿Cómo puedo ver mis ganancias a tiempo real?",
      answer:
        "El programa Afilia Olimpo cuenta con un sistema de reportes a tiempo real en donde podrás ver los resultados de tus acciones como clics, nuevos registros, nuevos depósitos, ganancias y mucho más.",
      answer2: null,
      status: false,
      link: null,
    },
    {
      id: 9,
      question:
        "¿Qué productos forman parte del programa de afiliados de Afilia Olimpo?",
      answer:
        "Afilia Olimpo ofrece su programa de afiliados para los productos de apuestas deportivas y casino online.",
      answer2: null,
      status: false,
      link: null,
    },
  ];

  const screenWidth = window.screen.width;
  const [viewPortWidth, setViewPortWidth] = useState(screenWidth);
  const [showAnswer, setShowAnswer] = useState(faqData);

  window.addEventListener("resize", (e) => {
    if (e.target.visualViewport.width > 0) {
      setViewPortWidth(e.target.visualViewport.width);
    }
  });


  const changeAnswer = (id) => {
    const newFaqData = showAnswer.map((item) => {
      if (id === item.id && !item.status) {
        return {
          ...item,
          status: true,
        };
      } else {
        return {
          ...item,
          status: false,
        };
      }
    });

    setShowAnswer(newFaqData);
  };

  return (
    <Fragment>
      <div className="page__faq py-5">
        <div className="container">
          <h1 className="section__title mt-0 mb-1">PREGUNTAS FRECUENTES</h1>
          <div
            className={
              "d-flex " +
              (viewPortWidth > 768
                ? " acordeon-desktop "
                : "  acordeon-mobile ")
            }
          >
            <ul className="faq px-0 my-0">
              {showAnswer.map((item, i) => {
                return (
                  <Fragment>
                    <li className="desktop__item mobile__item" key={item.id}>
                      <h3 className = { "desktop__item-question pointer" + 
                        (item.status ? " desktop__item-question-active ":" ") +
                        "mobile__item-question my-0 d-flex justify-content-between " +
                        (item.status ? " mobile__item-question-active " :" ") }
                        onClick={(_) => changeAnswer(item.id)}>

                        {item.question}
                        {viewPortWidth < 768 ? (
                          <span
                            className={
                              item.status
                                ? "icon-chevron-up"
                                : "icon-chevron-down"
                            }
                          ></span>
                        ) : null}
                      </h3>
                      {
                        showAnswer[i].status && 
                        <div className="desktop__item-answer my-0">
                          <Fragment key={showAnswer[i].id}>
                            <p className="desktop__item-answer-text mt-0">
                              {showAnswer[i].answer}
                              <a href="http://olimpo.bet" target="_blank">
                                {" "}
                                {showAnswer[i].link}{" "}
                              </a>
                              {showAnswer[i].answer2}
                            </p>
                          </Fragment>
                      </div>

                      }
                      
                      <div className="mobile__item-answer ">
                        {item.status ? (
                          <p
                            className="mobile__item-answer-text my-0"
                            key={item.id}
                          >
                            {item.answer}
                            <a href="http://olimpo.bet" target="_blank">
                              {" "}{item.link}{" "}
                            </a>
                            {item.answer2}
                          </p>
                        ) : null}
                      </div>
                    </li>
                  </Fragment>
                );
              })}
            </ul>
           
          </div>

          <p className="text-center mt-5 mb-0">
            Olimpo está comprometido a ofrecer las mejores promociones,
            productos y servicios.
          </p>
        </div>
      </div>
    </Fragment>
  );
};

export default Faq;
