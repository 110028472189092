import React, { Fragment } from "react";

const Contact = () => {
  return (
    <Fragment>
      <div className="contact contact__body">
        <div className="container">
          <div className="benefits__wrapper">
            <div className="section mb-5">
              <h1 className="section__title mt-0 w-100">Contacto</h1>
              <p className="section__description">
                Para cualquier duda o consulta por favor escríbenos a:{" "}
              </p>
              <ul className="list px-3">
                <li className="list__item">
                  {" "}
                  <span> </span>
                  <a href="#">contacto@afiliaolimpo.bet</a>.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Contact;
