import React from 'react';
import { NavLink } from 'react-router-dom';

const CardSteps = (props) => {
  const { number, title, description, register } = props;
  return (
    <div className="cardSteps">
      <span className="cardSteps__badge">{number}</span>
      <div className="cardSteps__content">
        <h2 className="cardSteps__title mb-0">{title}</h2>
        <p className="cardSteps__description">
          {description}
          <a className="cardSteps__link pointer">
            <span> </span>{register ? <NavLink to="./registrate" className={'c-yellow'}>Regístrate!</NavLink> : null}
          </a>
        </p>
      </div>
    </div>
  )
}

export default CardSteps;