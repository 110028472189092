import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import LogoIncabet from '../assets/images/olimpo-logo.png'
import Menu from './menu';


const Header = () => {
  const [menuStatus, setMenuStatus] = useState(false);

  const toogleMenu = () => {
    setMenuStatus(!menuStatus)
  }

  return (
    <div className="header-main">
      <div className="container">
        <div className="header-main__logo" id="logo">
          <NavLink className="header-logo" to="/" >
            <img className="header-logo__img" src={LogoIncabet} alt="Inkabet logo" style={{width: '216px', height: '45px'}} />
          </NavLink>
        </div>
        <div type="button" className="header-main__menu-barra pointer" onClick={() => { toogleMenu() }}>
          <span className="icon icon-bars"></span>
        </div>
        <div className="header__contenedor-desktop">
          <Menu handleToogleMenu={toogleMenu}/>
        </div>
      </div>
      {menuStatus &&
        <div className="header__contenedor-movil" >
          <Menu handleToogleMenu={toogleMenu}/>
        </div>
      }
      
    </div>

  )
}


export default Header;