import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Header from '../staticComponent/header';

// Components
import Home from '../pages/Home/Home';
import Benefits from '../pages/Benefits/Benefits';
import Contact from '../pages/Contact/Contact';
import Faq from '../pages/Faq/Faq';
import HowWork from '../pages/HowWork/HowWork';
import Login from '../pages/Login/Login';
import Products from '../pages/Products/Products';
import SignUp from '../pages/SignUp/SignUp';
import Footer from '../staticComponent/footer';
import MainBanner from '../components/MainBanner/MainBanner';
import Terms from '../pages/Terms/Terms';

const Router = () => {
  return (

    <BrowserRouter>
      <Header />
      <MainBanner />
      <Switch>
       
        <Route exact path='/recompensas' component={Benefits} />
        <Route exact path='/beneficios' component={Terms} />
        <Route exact path='/como-funciona' component={HowWork} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/preguntas-frecuentes' component={Faq} />
        <Route exact path='/productos' component={Products} />
        <Route exact path='/registrate' component={SignUp} />
        <Route exact path='/iniciar-sesion' component={Login} />
        <Route exact path='/' component={Home} />
    
      </Switch>
      <Footer />
    </BrowserRouter>
  )
}


export default Router;