import React from 'react';

const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="footer__link">
          <p>Contacto: <span>contacto@afiliaolimpo.bet</span></p>
        </div>
        <div className="footer__link">
          <a href="TC_Afiliados_Olimpo.pdf" target="_blank">Términos y condiciones</a>
        </div>
      </div>
      <hr></hr>
      <div className="footer__link footer__small text-center">
        <span>Powered by © <a className="link_olimpo" href="https://www.olimpo.bet/">Olimpo.bet</a></span>
      </div>

    </section>
  )
}

export default Footer;