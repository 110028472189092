import React from "react";

const Login = () => {
  return (
    <div className="login py-5">
      <div className="container">
        <h1>Login</h1>
      </div>
    </div>
  );
};

export default Login;
